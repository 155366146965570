import React from 'react';
import { Table } from 'react-bootstrap';
import Moment from 'react-moment';

import { Mission } from '../interfaces';

interface MissionsProps {
  missions: Mission[];
}

function Missions({ missions }: MissionsProps) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Updated</th>
          <th>Subscriptions</th>
          <th>Views</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {missions.sort((a: Mission, b: Mission) => a.Title.localeCompare(b.Title)).map((mission: Mission) => (
          <tr key={mission.ID}>
            <td>
              <a href={`https://steamcommunity.com/sharedfiles/filedetails/?id=${mission.PublishedFileId}`} target='_blank' rel='noopener noreferrer'>{mission.Title}</a>
            </td>
            <td><Moment fromNow>{mission.ItemUpdated}</Moment></td>
            <td>
              <span title='Active subscriptions'>{mission.Subscriptions}</span>
              &nbsp;/&nbsp;
              <span title='Lifetime subscriptions'>{mission.LifetimeSubscriptions}</span>
            </td>
            <td>{mission.Views}</td>
            <td>
              <a href={mission.FileURL} target='_blank' rel='noopener noreferrer'>Download</a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default Missions;
