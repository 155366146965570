import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { World } from '../interfaces';

interface WorldsProps {
  worlds: World[];
}

function Worlds({ worlds }: WorldsProps) {

  return (
    <Table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Missions</th>
        </tr>
      </thead>
      <tbody>
        {worlds.filter(world => world.Mapped).sort((a: World, b: World) => a.Title.localeCompare(b.Title)).map((world: World) => (
          <tr key={world.Name}>
            <td>
              <Link to={`/worlds/${world.Name}`}>{world.Title}</Link>
            </td>
            <td>{world.Count}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Worlds;
