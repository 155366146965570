import React from 'react';

import Fetch from './Fetch';
import Missions from './components/Missions';
import { Mission } from './interfaces';
import { worldMissionsUrl } from './urls';

interface WorldProps {
  worldName: string
}

function World({ worldName }: WorldProps) {
  const missions = Fetch<Mission[]>(worldMissionsUrl(worldName));

  return (
    <div>
      {missions.status === 'loading' && <div>Loading missions...</div>}
      {missions.status === 'loaded' && (
        <Missions missions={missions.data} />
      )}
      {missions.status === 'error' && <div>Error loading missions</div>}
    </div>
  );
}

export default World;
