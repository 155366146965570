import { createBrowserHistory } from "history";
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import './App.css';
import Mod from './Mod';
import World from './World';
import Worlds from './Worlds';

interface ModMatchParams {
  modId: string;
}
interface ModMatchProps extends RouteComponentProps<ModMatchParams> {}

interface WorldMatchParams {
  worldName: string;
}
interface WorldMatchProps extends RouteComponentProps<WorldMatchParams> {}

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <Navbar fixed="top" variant="light" bg="light">
        <Container>
          <Nav className="mr-auto">
            <LinkContainer to="/">
              <Navbar.Brand href="#">Workshop Missions</Navbar.Brand>
            </LinkContainer>
            <LinkContainer to="/worlds">
              <Nav.Link>Worlds</Nav.Link>
            </LinkContainer>
          </Nav>
        </Container>
      </Navbar>
      <Container>
        <Switch>
          <Route path="/mods/:modId" render={(routeProps: ModMatchProps) => (
            <Mod modId={routeProps.match.params.modId} />
          )} />

          <Route path="/worlds/:worldName" render={(routeProps: WorldMatchProps) => (
            <World worldName={routeProps.match.params.worldName} />
          )} />

          <Route path="/worlds">
            <Worlds />
          </Route>

          <Route path="/">
            <Redirect to="/worlds" />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
