import React from 'react';

import WorldsList from './components/Worlds';
import Fetch from './Fetch';
import { World } from './interfaces';
import { worldsUrl } from './urls';

function Worlds() {
  const worlds = Fetch<World[]>(worldsUrl());

  return (
    <div>
      {worlds.status === 'loading' && <div>Loading worlds...</div>}
      {worlds.status === 'loaded' && (
        <WorldsList worlds={worlds.data} />
      )}
      {worlds.status === 'error' && <div>Error loading worlds</div>}
    </div>
  );
}

export default Worlds;
