import React from 'react';

import Fetch from './Fetch';
import Missions from './components/Missions';
import { Mission } from './interfaces';
import { modMissionsUrl } from './urls';

interface ModProps {
  modId: string
}

function Mod({ modId }: ModProps) {
  const missions = Fetch<Mission[]>(modMissionsUrl(modId));

  return (
    <div>
      {missions.status === 'loading' && <div>Loading missions...</div>}
      {missions.status === 'loaded' && (
        <Missions missions={missions.data} />
      )}
      {missions.status === 'error' && <div>Error loading missions</div>}
    </div>
  );
}

export default Mod;
